<template>
  <div class="pd20x flex1">
    <AddClasses :visible="openClasses" @close="openClasses = false" detail_type="2" :options="options" :data="currentItem" :edit="editClass" @update="getData"></AddClasses>
    <div class="contentBox h100pct ofA">
      <div class="filter" style="padding:10px 20px">
        <div>专题套卷数量（{{ pagination.total }}条） </div>
        <div>
          <label>搜索：</label>
          <a-input-search v-model="filter.keywords" placeholder="搜索套卷标题" style="width: 200px" @search="()=>{pagination.page = 1;getData()}" class="mr10px"/>
          <a-button type="primary" @click="openClasses = true; currentItem = null; editClass = false">添加套卷</a-button>
        </div>
      </div>
      <a-table
          :columns="columns"
          :data-source="data"
          :pagination="pagination"
          :rowKey="'id'"
          @change="changePage"
      >
        <template slot="index" slot-scope="text, record">
          {{record.id}}
        </template>
        <template slot="classBagname" slot-scope="text, record">
          <div class="csP" @click="routerChange('套卷详情', '/exam/specialDetail', {id:record.id})">
            <div>{{record.name}}</div>
          </div>
        </template>
        <template slot="papers" slot-scope="text">
          <span style="color:#7ebae5">{{ text }}套</span>
        </template>
        <template slot="operation" slot-scope="text, record">
          <i class="z-icon editB" @click="routerChange('套卷详情', '/exam/specialDetail', {id:record.id})"></i>
          <i class="z-icon delete" @click="deleteItem(record.id)"></i>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script>
const columns = [{
  title: '序号',
  scopedSlots: { customRender: 'index' },
  align:'center'
},
  {
    title: '套卷名称',
    dataIndex: 'name',
    scopedSlots: { customRender: 'classBagname' },
    align:'center'
  }, {
    title: '标题',
    dataIndex: 'title',
    align:'center'
  }, {
    title: '试卷数量',
    dataIndex: 'papers',
    align:'center',
    scopedSlots: { customRender: 'papers' },
  }, {
    title: '原价',
    dataIndex: 'old_price',
    align:'center'
  }, {
    title: '现价',
    dataIndex: 'now_price',
    align:'center'
  }, {
    title: '创建时间',
    dataIndex: 'create_time'
  }, {
    title: '操作',
    dataIndex: 'operation',
    scopedSlots: { customRender: 'operation' },
  },
];
import AddClasses from '@/components/exam/addClasses'
import * as http from '@/libs/examapi'
import { routerlink } from '@/mixins/routerlink'
export default {
  name: 'theoryPapers',
  components: { AddClasses },
  mixins: [routerlink],
  data(){
    return{
      options: [],
      columns,
      filter:{
        cate_id: 0,
        keywords:''
      },
      pagination:{
        page: 1,
        size: '15',
        pageSize: 15
      },
      allSubject:[],
      data: [],
      areaval: '',
      openClasses: false,
      currentItem:'',
      editClass:false
    }
  },
  mounted(){
    Promise.all([this.getCates()]).then(res=>{
      this.getData()
    })
  },
  methods:{
    getData(){
      let request = {...this.filter,...this.pagination,detail_type:2}
      if(this.filter.cate_id == 0){
        request.cate_id = null
      }
      http.get_crouse_list(request).then(res=>{
        const pagination = { ...this.pagination }
        pagination.total = res.total;
        this.data = res.data
        this.pagination = pagination;

      }).catch(error=>{
        console.log(error)
      })
    },
    getCates(){
      return new Promise((resolve, reject)=>{
        http.get_subject_cate().then(res=>{
          this.options = res
          resolve()
        }).catch(error=>{
          reject()
        })
      })
    },
    deleteItem(id){
      let _this = this
      this.$confirm({
        title: '是否确定删除该套卷信息?',
        centered: true,
        onOk() {
          http.del_crouse_bag({id}).then(res=>{
            let {page, pagesize, total} = _this.pagination
            if(page>1 && Number(page-1)* 15 == total - 1){
              _this.pagination.page = Number(page-1)
            }
            _this.getData()
          }).catch(error=>{
            console.log(error)
          })
        },
      });
    },
    changePage({current}){
      this.pagination.page = current
      this.getData()
    },
    hideModal(){

    },
    onChange(){

    },
  }
}
</script>
<style lang="less" scoped>
.imgWrap{
  width: 120px;
  height: 70px;
  overflow: hidden;
  border-radius: 4px;
  img{
    width: 100%;
  }
}
</style>
