<template>
  <a-drawer
      :title="title"
      :visible="visible"
      :placement="'top'"
      @close="$emit('close')"
      :height="'100%'"
      :wrapClassName="'addClasses'"
  >
    <div class="content">
      <a-form
          :form="form"
          v-bind="formItemLayout"
      >
        <a-form-item label="套卷名称">
          <a-input
              v-decorator="['name', { initialValue: formData.name, rules: [{ required: true, message: '请输入套卷名称' }] }]"
          />
        </a-form-item>
        <a-form-item label="标题">
          <a-input
              v-decorator="['title', { initialValue: formData.title, rules: [{ required: true, message: '请输入标题' }] }]"
          />
        </a-form-item>
        <a-form-item label="分类">
          <a-select v-if="options" v-decorator="['cate_id', { initialValue: formData.cate_id, rules: [{ required: true, message: '请选择分类' }] }]" class="mr10px" placeholder="请选择分类">
            <a-select-option v-for="item in options" :key="item.id" :value="item.id">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="上传详情页封面图">
          <a-upload
              name="avatar"
              list-type="picture-card"
              :show-upload-list="false"
              :customRequest="customRequestDetailBg"
              v-decorator="['detailfileImg',
                            { rules: [{ required: true, message: '请上传图片' }] },
                        ]"
          >
            <img v-if="detail_bg_url" :src="detail_bg_url" alt="avatar" />
            <div v-else>
              <a-icon :type="'plus'" style="font-size:30px;color:#dcdcdc"/>
              <div>上传图片</div>
            </div>
          </a-upload>
        </a-form-item>
        <a-form-item label="价格">
          <div class="sTable">
            <div class="th">
              <div>原价</div>
              <div>现价</div>
            </div>
            <div class="tbody">
              <div class="taC">
                <a-form-item>
                  <a-input type="number" v-decorator="['old_price', { initialValue: formData.old_price, rules: [{ required: true, message: '请填写原价' }] }]" prefix="￥" suffix="元" style="width:160px"/>
                </a-form-item>
              </div>
              <div class="taC">
                <a-form-item>
                  <a-input type="number" v-decorator="['now_price', { initialValue: formData.now_price, rules: [{ required: true, message: '请填写现价' }] }]"  prefix="￥" suffix="元" style="width:160px"/>
                </a-form-item>
              </div>
            </div>
          </div>
        </a-form-item>
        <a-form-item label="可用次数">
          <a-input v-decorator="['valid_time', { initialValue: formData.valid_time, rules: [{ required: true, message: '请填写套卷内可模拟的次数' }] }]" style="width:160px;margin-right:10px"></a-input>次
        </a-form-item>
        <a-form-item label="开放套卷">
          <a-radio-group v-model="formData.is_show" @change="(e) => {onChange(e, 'is_show')}">
            <a-radio :value="0">隐藏</a-radio>
            <a-radio :value="1">开放</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
      <div style="text-align:right;padding-top:30px;padding-right:20%">
        <a-button class="mr20px" style="width:100px" @click="$emit('close')">取消</a-button>
        <a-button type="primary"  style="width:100px" @click="submit">立即添加</a-button>
      </div>
    </div>
  </a-drawer>
</template>
<script>
import { uploadImg } from '@/libs/api'
import * as http from '@/libs/examapi'
export default {
  name: 'addClasses',
  props: ['visible', 'options', 'data', 'edit', 'detail_type'],
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  data(){
    return{
      formItemLayout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 10 },
      },
      showP:false,
      loading: false,
      subjectList: [],
      ProfessList:[],
      detailUrl:'',
      title:'添加套卷',
      detailfileImg:[],
      detail_bg_url:'',
      formData:{
        name:'',title:'',cate_id:'',old_price:'',now_price:'',valid_time:3,is_show:1
      }
    }
  },
  watch:{
    visible(val){
      if(!val){
        this.reset()
      }else{
        if(this.edit){
          Object.assign(this.formData, this.data);
          this.title = '编辑套卷'
          this.detail_bg_url = this.data.detail_bg_url
          this.detail_type = this.data.detail_type
          setTimeout(()=>{
            this.form.setFieldsValue({'fileImg':[{uid:-1, name: 'image.png',status: 'done', url: this.data.img}]})
            this.form.setFieldsValue({'detailfileImg':[{uid:2, name: 'imageBg.png',status: 'done', url: this.data.detail_bg_url}]})
          })
        }else{
          this.title = '新增套卷'
        }
      }
    }
  },
  methods:{
    reset(){
      this.form.resetFields()
      this.detail_bg_url = ''
      this.loading = false
      this.detailUrl = ''
      this.showP = false
      this.formData = {
        name:'',title:'',cate_id:'',old_price:'',now_price:'',valid_time:3,is_show:1
      }
    },
    customRequestDetail(data){
      let formData = new FormData()
      formData.append('file',data.file)
      uploadImg(formData).then(res=>{
        if(res){
          this.detailUrl=res.fileUrl
        }
      },error=>{
        console.log(error)
      })

    },
    customRequestDetailBg(data){
      let formData = new FormData()
      formData.append('file',data.file)
      uploadImg(formData).then(res=>{
        if(res){
          this.detail_bg_url=res.fileUrl
        }
      },error=>{
        console.log(error)
      })
    },
    submit(){
      this.form.validateFields((err, values) => {
        if(err) return;
        let request = this._.cloneDeep(values)
        delete request.fileImg
        delete request.valid_time
        delete request.detailImg
        delete request.detailfileImg
        request.detail_bg_url = this.detail_bg_url
        request.detail_type = this.detail_type
        request.valid_time = values.valid_time
        request.is_show = this.formData.is_show
        if(this.edit){
          request.id = this.data.id
          http.edit_crouse_bag(request).then(res=>{
            this.$emit('close')
            this.$emit('update')
          }).catch(error=>{
            console.log(error)
          })
        }else{
          http.add_crouse_bag(request).then(res=>{
            this.$emit('close')
            this.$emit('update')
          }).catch(error=>{
            console.log(error)
          })
        }
      })
    },
    vaildatorPrice(rule, value, callback){
    }
  }
}
</script>
<style lang="less" scoped>
.sTable{
  width: 420px;
  border: 1px solid #eee;
  .th{
    background: #eeeeee;
    height: 32px;
    display: flex;
    align-items: center;
    text-align: center;
    >div{
      flex: 1;
    }
  }
  .tbody{
    padding: 20px 10px;
    display: flex;
    align-items: center;
    >div{
      flex: 1;
    }
  }
}
</style>
